<template class="tile">
	<v-menu open-on-hover top offset-x nudge-right="10" close-delay="100" max-width="200">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-if='itemdata.is_mob == true' dense class="tile white--text" link :to="'users/' + itemdata.id" v-bind="attrs" v-on="on">Monster: {{itemdata.name}}</v-list-item>
			<v-list-item v-else dense class="tile white--text" link :to="'users/' + itemdata.id" v-bind="attrs" v-on="on">Item: {{itemdata.name}}</v-list-item>
		</template>
		<v-card
			width="200"
			max-width="200"
			outlined
			tile
		>
			<v-list-item three-line>
				<v-list-item-content>
					<div class="text-overline mb-4">
						OVERLINE
					</div>
					<v-list-item-title class="text-h5 mb-1">
						Headline 5
					</v-list-item-title>
					<v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
				</v-list-item-content>

				<v-list-item-avatar
					tile
					size="80"
					color="grey"
				></v-list-item-avatar>
			</v-list-item>

			<v-card-actions>
				<v-btn
					outlined
					rounded
					text
				>
					Button
				</v-btn>
			</v-card-actions>
		</v-card>
</v-menu>
</template>
<script>
	import Vue from "vue";
export default {
  props:[ 
	'itemdata'
  ],
	data: () => ({
	}),
}
</script>