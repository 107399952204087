<template>
    <div class="ma-4">
      <v-toolbar rounded dense max-height="205">
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="account_id" class="px-4">
          <v-toolbar-title @click="zenyManager()" class="caption clickable pr-4" >Current Zeny: ${{getZenyValue(zeny)}}</v-toolbar-title>
        </div>
        <div v-if="account_id" class="px-4">
          <v-toolbar-title @click="getmoreCredits()" class="caption clickable pr-4" >Current Credits: ${{getZenyValue(credit,true)}}</v-toolbar-title>
        </div>

        <div v-if="account_id">
          <v-toolbar-title class="caption mr-4">
            <span>Welcome <strong>{{user_name}}</strong>
              <v-btn
                color="secondary"
                icon
                text
                small
                @click="viewProfile()"
                ><v-icon
                color="blue"
                >mdi-account</v-icon>
              </v-btn>
            </span>
          </v-toolbar-title>
        </div>
        <div v-if="account_id">
          <v-toolbar-title @click="logOut()" class="caption clickable" >Logout</v-toolbar-title>
        </div>
        <div v-else>
          <v-toolbar-title  @click="showlogin=true" class="caption clickable">Register</v-toolbar-title>
        </div>
    </v-toolbar>
  
    <v-dialog v-model="showlogin" persistent :width="600">
      <v-card v-if="login_mode">
        <v-card-title class="font-weight-bold text-sm-body-1" style="text-align: center">
          <v-icon class="mr-3" @click="showlogin = false">
            mdi-close
          </v-icon>
          Login</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
          <v-container>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="registroActual.userid"
                  :counter="22"
                  :rules="renameRules"
                  class="text-sm-body-1"
                  label="Account Name"
                  required
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="registroActual.user_pass"
                  :counter="22"
                  :rules="renameRules"
                  class="text-sm-body-1"
                  label="Password"
                  required
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="login_mode = false">Create a New Account</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="Login()">Login</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title 
              style="text-align: center">
          <v-icon class="mr-3" @click="showlogin = false">
            mdi-close
          </v-icon>Register</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <v-container>
              <v-row dense>
                <v-col>
                <v-text-field
                  v-model="registroActual.user_name"
                  :counter="22"
                  :rules="renameRules"
                  class="text-sm-body-1"
                  label="Display Name"
                  required
                  dense
                ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="registroActual.userid"
                    :counter="22"
                    :rules="renameRules"
                    label="Account Name"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="registroActual.user_pass"
                    :counter="22"
                    :rules="renameRules"
                    label="Password"
                    class="text-sm-body-1"
                    required
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="registroActual.user_pass2"
                    :counter="22"
                    :rules="renameRules"
                    type="password"
                    label="Confirm Password"
                    class="text-sm-body-1"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="registroActual.email"
                    :rules="emailRules"
                    label="Email"
                    class="caption"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="registroActual.email2"
                    :rules="emailRules"
                    label="Confirm E-mail"
                    class="text-body-2"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            </v-form>
          </v-card-text>
        <v-card-actions>
          <v-btn text @click="login_mode = true">You have an account? Login</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="Guardar()">Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDonation" :width="600">
      <v-card>
        <v-card-title class="font-weight-bold text-sm-body-1" style="text-align: center">Donation</v-card-title>
        <v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
    </div>
  </template>
  
<script>
import axios from 'axios'; 
import Vue from 'vue'
export default {
  props:[
    'account_id',
    'user_name',
    'title',
    'zeny',
    'credit',
  ],
	data: () => ({
    login_mode: true,
    showlogin: false,
    showDonation: false,
    showProfile: false,
    new_user_name: '',
    userid: null,
    password: null,
    password2: null,
    email: null,
    email2: null,
    registroActual: {},
    renameRules: [

      v => !!v || "This field is required",
      v => /^[a-zA-Z0-9\s]+$/.test(v) || "Only English alphabet characters, numbers and spaces are allowed.",
		  v => (v && (v.length >= 4 && v.length <= 64)) || "Must contain at least 4 characters"
    ],
    passRules: [
      v => !!v || "This field is required",
      v => v && /^[a-zA-Z0-9\s]+$/.test(v) || "Only English alphabet characters, numbers and spaces are allowed.",
		  v => (v && (v.length >= 6 && v.length <= 64)) || "Must contain at least 6 characters"
    ],
    emailRules: [ 
      v => !!v || "This field is required",
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
      v => v && v.indexOf('.ru') === -1 || "We have a problem."
    ],
    valid: true,
    v: '',
    balance:{},

	}),
  methods: {
    close(){
      this.showlogin = false;
    },
    isEmailValid(email) {
      const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegEx.test(email);
    },
    Guardar(){
      if(!this.valid){
        Vue.swal.fire({ icon: "warning", text: "Please fill out the form correctly" });
        return;
      }
      if(this.registroActual.user_pass != this.registroActual.user_pass2){ Vue.swal.fire({ icon: "warning", text: "Passwords do not match" });return; }
      if(this.registroActual.email != this.registroActual.email2){ Vue.swal.fire({ icon: "warning", text: "Emails do not match" }); return; }

      axios
      .post('login/register',this.registroActual)
      .then(response => {
        this.$emit('login',response.data);
        this.showlogin = false;
        this.registroActual = {};
      })
      .catch(this.MSGBOX);
    },
    Login(){
      axios
      .post('login/login',this.registroActual)
      .then(response => {
        this.$emit('login',response.data);
        this.showlogin = false;
      })
      .catch(this.MSGBOX);
    },
    logOut(){
      this.$emit('logout');
    },
    zenyManager(){

    },
    viewProfile(){
      this.$emit('showprofile');
    },
    getmoreCredits(){
      this.showDonation = true;
    },
    sendDonation(){
      let registroActual = {};
      registroActual.cmd = '_donations';
      registroActual.notify_url = ''; //'<?php echo $this->url('donate', 'notify', array('_host' => true)) ?>" />
      registroActual.return = ''; //<?php echo $this->url('main', 'index', array('_host' => true)) ?>" />
      registroActual.custom = ''; //" value="<?php echo $customDataEscaped ?>" />
      registroActual.business = ''; //" value="<?php echo $businessEmail ?>" />
      registroActual.item_name = 'Donation'; //" value="<?php echo $itemName ?>" />
      registroActual.amount = 5; //" value="<?php echo (float)$amount ?>" />
      registroActual.no_shipping = 0; //" value="0" />
      registroActual.no_note = 1; //" value="1" />
      registroActual.currency_code = 'USD'; //" value="<?php echo $donationCurrency ?>" />
      registroActual.tax = 0; //" value="0" />
      registroActual.lc = 'US'; //" value="US" />
      registroActual.bn = 'PP-DonationsBF'; //" value="PP-DonationsBF" />
    },
  },
    watch: {
    },
};
</script>