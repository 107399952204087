<template>
    <span>
      <v-dialog v-model="show" persistent :fullscreen="fullscreen" :max-width="max_width" overlay transition="dialog-bottom-transition" scrollable>
        <v-card v-if="modo_registro">
        <v-card-title class="primary font-weight-bold text-sm-body-6 white--text"  style="text-align: center">
            Register
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Close()" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-container>
            <v-form>
              <span class="text-subtitle-2">Registration Form</span>
                <v-text-field
                  class="mt-4"
                  dense
                  outlined
                  label="Your Name"
                  placeholder="Your Name"
                  v-model="user_name"
                />
                <v-text-field
                    dense
                    outlined
                    label="Account Name"
                    placeholder="Account Name"
                    v-model="userid"
                />
                <v-text-field
                  dense
                  outlined
                  label="Email"
                  placeholder="email"
                  v-model="email"
                  :rules="rules.correo"
                />
                <v-text-field
                  dense
                  outlined
                  label="Password"
                  placeholder="Password"
                  :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showpass ? 'text' : 'password'"
                  @click:append="showpass = !showpass"
                  v-model="user_pass"
                  :rules="rules.valor"
                />
              <v-radio-group
                class="mt-n4"
                v-model="registroActual.sexo"
                >
                <template v-slot:label>
                  <div>Sex</div>
                </template>
                <v-radio
                  label="Male"
                  value="M"
                />
                <v-radio
                  label="Female"
                  value="F"
                />
              </v-radio-group>
            </v-form>
          </v-container>
          
          <v-btn class="mr-2" color="primary" @click="modo_registro=false">
            Login section
          </v-btn>
          <v-btn class="ml-2" color="success" @click="Registrar()">
            Complete registration
          </v-btn>
        </v-card-text>
        </v-card>
        <v-card v-else class="text-center">
          <v-card-title class="primary font-weight-bold text-sm-body-6 white--text"  style="text-align: center">
            Login section
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Close()" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-container fluid>
            <v-form>
              <v-text-field
                class="pt-4"
                dense
                outlined
                label="Account"
                prepend-inner-icon="mdi-profile"
                v-model="userid"
              />
              <v-text-field
                dense
                outlined
                label="Password"
                placeholder="Password"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
                v-model="user_pass"
                :rules="rules.valor"
              />
            </v-form>
          </v-container>
          <v-checkbox
            class="mt-n8"
            v-model="save_seccion"
            label="Mantener seccion iniciada"
          />
          <v-btn
            class="mr-2"
            color="primary"
            @click="modo_registro=true"
            >
            Create Account
          </v-btn>
          <v-btn
            class="ml-2"
            color="success"
            @click="Login()"
            >
            Login
          </v-btn>
        </v-card-text>
          
        </v-card>
      </v-dialog>
      
    </span>
</template>
<script>
import axios from 'axios'; 
import Vue from 'vue'
export default {
    props:[
      'id',
      'show',
      'isNew',
    ],
    data: () => ({
      registroActual: {},
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      contacto_id: null,
      modo_registro: false,
      fullscreen: false,
      max_width: 480,
      save_seccion: true,
      showpass: false,
      email: '',
      userid: '',
      user_pass: '',
      valid: true,
      renameRules: [
        v => !!v || "This field is required",
        v => /^[a-zA-Z0-9\s]+$/.test(v) || "Only English alphabet characters, numbers and spaces are allowed.",
        v => (v && (v.length >= 4 && v.length <= 64)) || "Must contain at least 4 characters"
        ],
      isVertical: true,
      rules: {},
    }),
    mounted() {
    },
    methods: {
      Login(){
        this.registroActual.userid = this.userid;
        this.registroActual.user_pass = this.user_pass;
        axios
          .post('user/login',this.registroActual)
          .then(response => {
            let data_user = response.data;
            this.registroActual = {};
            this.$emit('login',data_user);
            return;
          })
          .catch(this.MSGBOX);
      },
      Registrar(){
        this.registroActual.email = this.email;
        this.registroActual.userid = this.userid;
        this.registroActual.user_name = this.user_name;
        this.registroActual.user_pass = this.user_pass;
        axios
          .post('user/register',this.registroActual)
          .then(response => {
            let data_user = response.data.user;
            this.registroActual = {};
            this.$emit('registrar',data_user);
            return;
          })
          .catch(this.MSGBOX);
      },
      Close(){
        this.$emit('close');
      },

    },
    watch: {
    }
}
</script>