import Vue from 'vue'
import Router from 'vue-router'
//import Home from 
import ItemInfo from '../views/ItemInfo.vue'

Vue.use(Router);

let routeslist = [
  { path: '/',name: 'Home',component: '../views/Main.vue',meta: { requireAuth: false, breadcrumb: [{ name: "home" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/Main.vue'), meta: { breadcrumb: [] } },
  { path: '/about', name: 'About', component: '../views/AboutRagnamex.vue', meta: { requireAuth: false, breadcrumb: [{ name: "about" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/AboutRagnamex.vue'), meta: { breadcrumb: [] } },
  { path: '/features', name: 'Features', component: '../views/Features.vue', meta: { requireAuth: false, breadcrumb: [{ name: "feature" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/Features.vue'), meta: { breadcrumb: [] } },
  { path: '/serverinfo', name: 'Server Info', component: '../views/ServerInfo.vue', meta: { requireAuth: false, breadcrumb: [{ name: "serverinfo" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/ServerInfo.vue'), meta: { breadcrumb: [] } },
  { path: '/ranking', name: 'Ranking', component: '../views/RankingView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "ranking" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/RankingView.vue'), meta: { breadcrumb: [] } },
  { path: '/deathlog', name: 'DeathLog', component: '../views/DeathlogView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "deathlog" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/DeathlogView.vue'), meta: { breadcrumb: [] } },
  { path: '/tokenragnamex', name: 'Ragnamex Token', component: '../views/RagnamexToken.vue', meta: { requireAuth: false, breadcrumb: [{ name: "serverinfo" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/RagnamexToken.vue'), meta: { breadcrumb: [] } },
  { path: '/cashshop', name: 'Cashshop', component: '../views/CashShop.vue', meta: { requireAuth: false, breadcrumb: [{ name: "serverinfo" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/CashShop.vue'), meta: { breadcrumb: [] } },
  { path: '/vip', name: 'VIP', component: '../views/VipView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "serverinfo" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/VipView.vue'), meta: { breadcrumb: [] } },
  { path: '/services', name: 'Services', component: '../views/Services.vue', meta: { requireAuth: false, breadcrumb: [{ name: "services" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/Services.vue'), meta: { breadcrumb: [] } },
  { path: '/downloads', name: 'Downloads', component: '../views/DownloadView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "downloads" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/DownloadView.vue'), meta: { breadcrumb: [] } },
  { path: '/guides', name: 'Guides', component: '../views/Guides.vue', meta: { requireAuth: false, breadcrumb: [{ name: "guides" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/Guides.vue'), meta: { breadcrumb: [] } },
  { path: '/market', name: 'Market', component: '../views/MarketVue.vue', meta: { requireAuth: false, breadcrumb: [{ name: "market" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/MarketVue.vue'), meta: { breadcrumb: [] } },
  
  { path: '/ref/:referido', name: 'Home Referido', component: '../views/HomeView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "home" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/HomeView.vue'), meta: { breadcrumb: [] } },
  { path: '/db', name: 'Data Base - Ragnamex', component: '../views/db/profession.vue', meta: { requireAuth: false, breadcrumb: [{ name: "Professions" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/db/profession.vue'), meta: { breadcrumb: [] } },
  { path: '/db/proffesions', name: 'Home Referido', component: '../views/db/profession.vue', meta: { requireAuth: false, breadcrumb: [{ name: "Professions" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/db/profession.vue'), meta: { breadcrumb: [] } },
  { path: '/db/item', name: 'Home Referido', component: '../views/HomeView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "home" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/HomeView.vue'), meta: { breadcrumb: [] } },
  { path: '/db/item/:id', name: 'Home Referido', component: '../views/HomeView.vue', meta: { requireAuth: false, breadcrumb: [{ name: "home" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/HomeView.vue'), meta: { breadcrumb: [] } },
  { path: '/donate', name: 'Donations', component: '../views/HowDonate.vue', meta: { requireAuth: false, breadcrumb: [{ name: "donate" }] }, component: () => import(/* webpackChunkname: "login" */ '@/views/HowDonate.vue'), meta: { breadcrumb: [] }
  },
  {
    path: '/donate/success',
    name: 'Donaciones Success',
    component: '../views/donate/donate_success.vue',
    meta: {
      requireAuth: false,
      breadcrumb: [{ name: "donate" }]
    },
    component: () =>
      import(/* webpackChunkname: "login" */ '@/views/donate/donate_success.vue'),
      meta: {
        breadcrumb: []
      }
  },
  {
    path: '/donate/cancel',
    name: 'Donaciones Cancel',
    component: '../views/donate/donate_cancel.vue',
    meta: {
      requireAuth: false,
      breadcrumb: [{ name: "donate" }]
    },
    component: () =>
      import(/* webpackChunkname: "login" */ '@/views/donate/donate_cancel.vue'),
      meta: {
        breadcrumb: []
      }
  },
  {
    path: '/auction',
    name: 'Auction House',
    component: '../views/AuctionHouse.vue',
    meta: {
      requireAuth: false,
      breadcrumb: [{ name: "auctionhouse" }]
    },
    component: () =>
      import(/* webpackChunkname: "login" */ '@/views/AuctionHouse.vue'),
      meta: {
        breadcrumb: []
      }
  },
  {
    path: '/item/:id',
    name: 'Item',
    component: ItemInfo,
    meta: {
      requireAuth: false,
      breadcrumb: [{ name: "item" }]
    },
    component: () =>
      import(/* webpackChunkname: "login" */ '@/views/ItemInfo.vue'),
      meta: {
        breadcrumb: []
      }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/wowah',
    name: 'wowauh',
    component: () => import('../views/wowaction.vue')
  }
];

const router = new Router({
  mode: 'history',
  routes: routeslist
});

router.beforeEach((to, from, next) => {
  document.title = `${ process.env.VUE_APP_TITLE} :: ${ to.name }`
  next();
});

export default router;
