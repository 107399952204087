// i18n.js

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    my_account: 'Account',
    login: 'Login',
    logout: 'Logout',
    referrals: 'Referrals',
    welcome: 'Welcome',
    about: 'About Us',
    confirm_password_label: 'Confirm Password',
    donations: 'Donations',
    donation: 'Donation',
    information: 'Information',
    f_1: 'Mode Hardcore',
    f_1_description: 'Accept the challenge of reaching the highest level in Hardcore mode. Only the most skilled and dedicated players can reach the top, where a single mistake can mean losing all your progress in the adventure. If you seek a true challenge and want to prove your worth, you will love this mode. Enjoy the adrenaline rush and push your limits!',
    
    // ... otras traducciones en inglés
    title: "What is Ragnamex Hardcore?",
    content: {
      p1: "Ragnamex Hardcore is a highly modified Ragnarok Online server that incorporates elements from other games into its mechanics, adding professions, an auction house, and new formulas while removing restrictions from the base game. Due to these modifications, many elements of the game have been adjusted to maintain balance.",
      p2: "The most distinctive feature of Ragnamex Hardcore is its 'Hardcore' mode. In this mode, players face an extreme challenge: if they are eliminated by a monster outside a safe zone, they will lose their maximum level and all their equipment, forcing them to start their adventure from scratch. This mechanic adds a layer of tension and excitement, making every decision and combat crucial.",
      p3: "Ragnamex Hardcore is not just a game; it is an intense experience that tests players' skill, strategy, and perseverance. Join the adventure and see if you have what it takes to survive in this unforgiving world!",
    },
    footer: "&copy; 2024 - GM Triedge",
    f_2: 'Profession System',
    f_2_description: 'In Ragnamex Hardcore, you not only need to choose a class for your adventure, but also a profession. We offer a variety of professions to choose from: from gathering, like mining and herbalism, to production, like alchemy, blacksmithing, enchanting, tailoring, and many more. You will always find players who value the fruits of your labor. Make your choice and stand out in the world of Ragnamex Hardcore!',
    f_3: 'Auction System',
    f_3_description: 'Ragnamex Hardcore is the only server with an exclusive auction system. Buy and sell items with ease, while our system does all the work for you, ensuring your items sell at attractive prices. Join and take advantage of this innovative marketplace!',
    downloads: 'Downloads',
    download: 'Download',
    register: 'Register',
    buy_by: 'Purchase for',
    m_1: 'Explore a completely new adventure in a familiar world, with many new items and ways to play. Enjoy the classic experience with a fresh twist, and above all... don\'t die! If you die, remember that each death is just a new beginning. Only the most persevering achieve great feats.',
    m_2: 'Welcome to the challenging world of Ragnamex Hardcore!',
    page_vip1: 'Advantages of VIP Mode',
    page_vip2: 'Discover all the benefits of having VIP mode active.',
    page_vip3: 'Enhanced Experience:',
    page_vip4: 'Monster experience increases by +50%.',
    page_vip5: 'Battle Manual Experience +50%.',
    page_vip6: 'Exclusive Access:',
    page_vip7: 'Access to an Extra Storage.',
    page_vip8: 'VIP Commands:',
    page_vip9: 'Allows using this command, which enables selling anywhere.',
    page_vip10: 'Cooldown time is now 5 minutes, down from 30 minutes.',
    page_vip11: 'Profession Benefits:',
    page_vip12: 'Crafting time reduction from 50% to 75%.',
    page_vip13: 'Chance to obtain extra resources.',
    page_vip14: 'Chance to obtain an extra product.',
    page_vip15: 'Chance to reduce material costs.',
    ragnamex_token1: 'What is the Ragnamex Token?',
    ragnamex_token2: 'Learn all about the Ragnamex Token and how you can benefit from it.',
    ragnamex_token3: 'Obtaining the Ragnamex Token:',
    ragnamex_token4: 'The Ragnamex Token can be obtained for 15 Credits from our website.',
    ragnamex_token5: 'Options when using the Ragnamex Token:',
    ragnamex_token6: 'When using the Ragnamex Token, the player will be given two options:',
    ragnamex_token7: 'Obtain 30 days of VIP.',
    ragnamex_token8: 'Obtain 700 Cashpoints.',
    ragnamex_token9: 'Exchanging the Ragnamex Token:',
    ragnamex_token10: 'It is important to note that the Ragnamex Token is not tied to the player or the account. This means it is possible to:',
    ragnamex_token11: 'Trade it with other players.',
    ragnamex_token12: 'Sell it in the auction system and wait for a player to pay Zenys in exchange for the Ragnamex Token.',
    step_paypal1: 'Donation Guide using PayPal',
    step_paypal2: 'Follow the steps below to make your donation.',
    step_paypal3: 'Step 1',
    step_paypal4: 'Each USD sent via PayPal equals 1 Credit.',
    step_paypal5: 'Step 2',
    step_paypal6: 'Send the desired amount to the email: donate.imperialro@outlook.com',
    step_paypal7: 'Step 3',
    step_paypal8: 'If possible, send the money as a Donation.',
    step_paypal9: 'Step 4',
    step_paypal10: 'Once the donation is made, contact @gmtriedge on the Ragnamex Discord for verification.',
    step_paypal11: 'Step 5',
    step_paypal12: 'Once the verification is completed, the Credits will appear in your account.',
    service_p1: 'Game Services',
    service_p2: 'There are certain ingame services available within the game.',
    service_p3: 'Change Your Character\'s Gender',
    service_p4: 'Allows you to change your current character\'s gender.',
    service_p5: 'Name Change',
    service_p6: 'Allows you to change your character\'s name.',
    service_p7: 'Transfer Your Character to Another Account',
    service_p8: 'Allows you to transfer your character to a different account.',

  },
  es: {
    my_account: 'Cuenta',
    login: 'Iniciar Sesion',
    logout: 'Desconectarse',
    referrals: 'Referidos',
    welcome: 'Bienvenido',
    about: 'Acerca de nosotros',
    confirm_password_label: 'Confirmar Contraseña',
    donations: 'Donaciones',
    donation: 'Donacion',
    information: 'Información',
    f_1: 'Modo Hardcore',
    f_1_description: 'Acepta el desafío de alcanzar el máximo nivel en el modo Hardcore. Solo los jugadores más habilidosos y dedicados pueden llegar a la cima, donde un solo error puede significar perder todo tu progreso en la aventura. Si buscas un verdadero reto y deseas demostrar tu valía, este modo te encantará. ¡Disfruta de la adrenalina y pon a prueba tus límites!',
    // ... otras traducciones en español
    title: "¿Qué es Ragnamex Hardcore?",
    content: {
      p1: "Ragnamex Hardcore es un servidor de Ragnarok Online altamente modificado, que incorpora elementos de otros juegos en sus mecánicas, agrega profesiones, una casa de subastas, y nuevas fórmulas eliminando restricciones del juego base. Debido a estas modificaciones, muchos elementos del juego han sido ajustados para mantener el balance.",
      p2: "La característica más distintiva de Ragnamex Hardcore es su modo 'Hardcore'. En este modo, los jugadores enfrentan un desafío extremo: si son eliminados por un monstruo fuera de una zona segura, perderán su nivel máximo y todo su equipamiento, obligándolos a comenzar su aventura desde cero. Esta mecánica agrega una capa de tensión y emoción, haciendo que cada decisión y combate sean cruciales.",
      p3: "Ragnamex Hardcore no es solo un juego, es una experiencia intensa que pone a prueba la habilidad, la estrategia y la perseverancia de los jugadores. ¡Únete a la aventura y descubre si tienes lo que se necesita para sobrevivir en este mundo implacable!",
    },
    footer: "&copy; 2024 - GM Triedge",
    f_2: 'Sistema de profesiones',
    f_2_description: 'En Ragnamex Hardcore, no solo debes elegir una clase para tu aventura, sino también una profesión. Disponemos de una variedad de profesiones para seleccionar: desde recolección, como minería y herboristería, hasta producción, como alquimia, herrería, encantamiento, sastrería y muchas más. Siempre encontrarás jugadores que valoren el fruto de tu trabajo. ¡Haz tu elección y destaca en el mundo de Ragnamex Hardcore!',
    f_3: 'Sistema de Subastas',
    f_3_description: 'Ragnamex Hardcore es el único servidor con un exclusivo sistema de subastas. Compra y vende objetos con facilidad, mientras nuestro sistema hace todo el trabajo por ti, asegurando que tus artículos se vendan a precios atractivos. ¡Únete y aprovecha este innovador mercado!',
    downloads: 'Descargas',
    download: 'Descarga',
    register: 'Registro',
    buy_by: 'Comprar por',
    m_1: 'Explora una aventura totalmente nueva en un mundo familiar, con muchos nuevos objetos y formas de jugar. Disfruta de la experiencia clásica con un aire fresco y, sobre todo... ¡no mueras! Si mueres, recuerda que cada muerte es solo un nuevo comienzo. Solo los más perseverantes logran grandes hazañas.',
    m_2: 'Bienvenido al desafiante mundo de Ragnamex Hardcore!',
    page_vip1: 'Ventajas del Modo VIP',
    page_vip2: 'Descubre todos los beneficios de tener el modo VIP activo.',
    page_vip3: 'Experiencia Mejorada:',
    page_vip4: 'Experiencia de los monstruos aumenta un +50%.',
    page_vip5: 'Battle Manual Experiencia +50%.',
    page_vip6: 'Acceso Exclusivo:',
    page_vip7: 'Acceso a un Almacén Extra.',
    page_vip8: 'Comandos VIP:',
    page_vip9: 'Permite usar este comando, el cual habilita la posibilidad de vender en cualquier lugar.',
    page_vip10: 'Tiempo de reutilización ahora es de 5 minutos, baja de 30 minutos.',
    page_vip11: 'Beneficios en Profesiones:',
    page_vip12: 'Reducción del tiempo de Crafteo desde un 50% a un 75%.',
    page_vip13: 'Posibilidad de obtener recursos extra.',
    page_vip14: 'Posibilidad de obtener un producto extra.',
    page_vip15: 'Posibilidad de reducir el coste de materiales.',
    ragnamex_token1: '¿Qué es el Ragnamex Token?',
    ragnamex_token2: 'Conoce todo sobre el Ragnamex Token y cómo puedes beneficiarte de él.',
    ragnamex_token3: 'Obtención del Ragnamex Token:',
    ragnamex_token4: 'El Ragnamex Token puede obtenerse por 15 Credits desde nuestra página web.',
    ragnamex_token5: 'Opciones al usar el Ragnamex Token:',
    ragnamex_token6: 'Al momento de usar el Ragnamex Token, se le darán al jugador dos opciones:',
    ragnamex_token7: 'Obtener 30 días VIP.',
    ragnamex_token8: 'Obtener 700 Cashpoints.',
    ragnamex_token9: 'Intercambio del Ragnamex Token:',
    ragnamex_token10: 'Es importante considerar que el Ragnamex Token no está ligado al jugador o la cuenta. Esto significa que es posible:',
    ragnamex_token11: 'Intercambiarlo con otros jugadores.',
    ragnamex_token12: 'Venderlo en el sistema de subastas y esperar que algún jugador pague Zenys a cambio de obtener el Ragnamex Token.',
    step_paypal1: 'Guía de Donación usando PayPal',
    step_paypal2: 'Sigue los pasos a continuación para realizar tu donación.',
    step_paypal3: 'Paso 1',
    step_paypal4: 'Cada USD enviado por PayPal equivale a 1 Credit.',
    step_paypal5: 'Paso 2',
    step_paypal6: 'Envía la cantidad deseada al email: donate.imperialro@outlook.com',
    step_paypal7: 'Paso 3',
    step_paypal8: 'De ser posible, envía el dinero como Donación.',
    step_paypal9: 'Paso 4',
    step_paypal10: 'Una vez hecha la donación, comunícate con @gmtriedge en el Discord de Ragnamex para que se haga la verificación.',
    step_paypal11: 'Paso 5',
    step_paypal12: 'Una vez completada la verificación, los Credits aparecerán en tu cuenta.',
    service_p1: 'Servicios ingame',
    service_p2: 'Existen ciertos servicios Ingame dentro del juego.',
    service_p3: 'Cambio de Género de tu Personaje',
    service_p4: 'Permite cambiar el género de tu personaje actual.',
    service_p5: 'Cambio de Nombre',
    service_p6: 'Permite cambiar el nombre de tu personaje.',
    service_p7: 'Transferir tu Personaje a Otra Cuenta',
    service_p8: 'Permite transferir tu personaje a una cuenta diferente.',
  },
  pt: {
    login: 'Entrar',
    logout: 'Sair',
    title: "O que é Ragnamex Hardcore?",
    content: {
      p1: "Ragnamex Hardcore é um servidor de Ragnarok Online altamente modificado que incorpora elementos de outros jogos em suas mecânicas, adicionando profissões, uma casa de leilões e novas fórmulas, enquanto remove restrições do jogo base. Devido a essas modificações, muitos elementos do jogo foram ajustados para manter o equilíbrio.",
      p2: "A característica mais distintiva de Ragnamex Hardcore é o modo 'Hardcore'. Nesse modo, os jogadores enfrentam um desafio extremo: se forem eliminados por um monstro fora de uma zona segura, perderão seu nível máximo e todo seu equipamento, forçando-os a recomeçar sua aventura do zero. Essa mecânica adiciona uma camada de tensão e emoção, tornando cada decisão e combate cruciais.",
      p3: "Ragnamex Hardcore não é apenas um jogo; é uma experiência intensa que testa a habilidade, estratégia e perseverança dos jogadores. Junte-se à aventura e descubra se você tem o que é necessário para sobreviver neste mundo implacável!",
    },
    footer: "&copy; 2024 - GM Triedge",
    f_1: 'Modo Hardcore',
    f_1_description: 'Aceite o desafio de alcançar o nível máximo no modo Hardcore. Apenas os jogadores mais habilidosos e dedicados podem chegar ao topo, onde um único erro pode significar perder todo o seu progresso na aventura. Se você busca um verdadeiro desafio e deseja provar seu valor, vai adorar este modo. Desfrute da adrenalina e teste seus limites!',
    f_2: 'Sistema de Profissões',
    f_2_description: 'No Ragnamex Hardcore, você não só precisa escolher uma classe para sua aventura, mas também uma profissão. Temos uma variedade de profissões para escolher: desde coleta, como mineração e herbologia, até produção, como alquimia, ferraria, encantamento, alfaiataria e muitas mais. Você sempre encontrará jogadores que valorizam os frutos do seu trabalho. Faça sua escolha e destaque-se no mundo de Ragnamex Hardcore!',
    f_3: 'Sistema de Leilões',
    f_3_description: 'Ragnamex Hardcore é o único servidor com um exclusivo sistema de leilões. Compre e venda itens com facilidade, enquanto nosso sistema faz todo o trabalho por você, garantindo que seus itens sejam vendidos a preços atraentes. Junte-se e aproveite este mercado inovador!',
    downloads: 'Downloads',
    download: 'Download',
    register: 'Registro',
    buy_by: 'Comprar por',
    m_1: 'Explore uma aventura completamente nova em um mundo familiar, com muitos novos objetos e formas de jogar. Aproveite a experiência clássica com um toque fresco e, acima de tudo... não morra! Se morrer, lembre-se de que cada morte é apenas um novo começo. Apenas os mais perseverantes alcançam grandes feitos.',
    m_2: 'Bem-vindo ao desafiante mundo de Ragnamex Hardcore!',
    page_vip1: 'Vantagens do Modo VIP',
    page_vip2: 'Descubra todos os benefícios de ter o modo VIP ativo.',
    page_vip3: 'Experiência Melhorada:',
    page_vip4: 'Experiência dos monstros aumenta em +50%.',
    page_vip5: 'Experiência do Manual de Batalha +50%.',
    page_vip6: 'Acesso Exclusivo:',
    page_vip7: 'Acesso a um Armazém Extra.',
    page_vip8: 'Comandos VIP:',
    page_vip9: 'Permite usar este comando, que habilita a possibilidade de vender em qualquer lugar.',
    page_vip10: 'O tempo de recarga agora é de 5 minutos, reduzido de 30 minutos.',
    page_vip11: 'Benefícios nas Profissões:',
    page_vip12: 'Redução do tempo de Crafting de 50% a 75%.',
    page_vip13: 'Possibilidade de obter recursos extras.',
    page_vip14: 'Possibilidade de obter um produto extra.',
    page_vip15: 'Possibilidade de reduzir os custos de materiais.',
    ragnamex_token1: 'O que é o Ragnamex Token?',
    ragnamex_token2: 'Saiba tudo sobre o Ragnamex Token e como você pode se beneficiar dele.',
    ragnamex_token3: 'Obtenção do Ragnamex Token:',
    ragnamex_token4: 'O Ragnamex Token pode ser obtido por 15 Créditos em nosso site.',
    ragnamex_token5: 'Opções ao usar o Ragnamex Token:',
    ragnamex_token6: 'Ao usar o Ragnamex Token, o jogador terá duas opções:',
    ragnamex_token7: 'Obter 30 dias de VIP.',
    ragnamex_token8: 'Obter 700 Cashpoints.',
    ragnamex_token9: 'Troca do Ragnamex Token:',
    ragnamex_token10: 'É importante considerar que o Ragnamex Token não está vinculado ao jogador ou à conta. Isso significa que é possível:',
    ragnamex_token11: 'Trocar com outros jogadores.',
    ragnamex_token12: 'Vendê-lo no sistema de leilão e esperar que algum jogador pague Zenys em troca de obter o Ragnamex Token.',
    step_paypal1: 'Guia de Doação usando PayPal',
    step_paypal2: 'Siga os passos abaixo para fazer sua doação.',
    step_paypal3: 'Passo 1',
    step_paypal4: 'Cada USD enviado pelo PayPal equivale a 1 Crédito.',
    step_paypal5: 'Passo 2',
    step_paypal6: 'Envie a quantidade desejada para o email: donate.imperialro@outlook.com',
    step_paypal7: 'Passo 3',
    step_paypal8: 'Se possível, envie o dinheiro como Doação.',
    step_paypal9: 'Passo 4',
    step_paypal10: 'Uma vez feita a doação, entre em contato com @gmtriedge no Discord do Ragnamex para a verificação.',
    step_paypal11: 'Passo 5',
    step_paypal12: 'Uma vez que a verificação for concluída, os Créditos aparecerão em sua conta.',
    service_p1: 'Serviços ingame',
    service_p2: 'Existem certos serviços ingame disponíveis dentro do jogo.',
    service_p3: 'Troca de Gênero do seu Personagem',
    service_p4: 'Permite trocar o gênero do seu personagem atual.',
    service_p5: 'Mudança de Nome',
    service_p6: 'Permite trocar o nome do seu personagem.',
    service_p7: 'Transferir seu Personagem para Outra Conta',
    service_p8: 'Permite transferir seu personagem para uma conta diferente.',

  },
  fil: {
    login: 'Mag-login',
    logout: 'Mag-logout',
    title: "Ano ang Ragnamex Hardcore?",
    content: {
      p1: "Ang Ragnamex Hardcore ay isang highly modified na Ragnarok Online server na nag-iincorporate ng mga elemento mula sa ibang laro sa kanyang mga mekanika, nagdadagdag ng mga propesyon, isang auction house, at mga bagong formula habang tinatanggal ang mga restriksyon mula sa base game. Dahil sa mga modipikasyong ito, maraming elemento ng laro ang inayos upang mapanatili ang balanse.",
      p2: "Ang pinakakilalang tampok ng Ragnamex Hardcore ay ang 'Hardcore' mode nito. Sa mode na ito, ang mga manlalaro ay humaharap sa isang matinding hamon: kung sila ay mapapatay ng isang halimaw sa labas ng ligtas na lugar, mawawala ang kanilang maximum na antas at lahat ng kanilang kagamitan, na magpupuwersa sa kanilang magsimula ng kanilang pakikipagsapalaran mula sa simula. Ang mekanikang ito ay nagdadagdag ng isang patong ng tensyon at kaguluhan, na ginagawang mahalaga ang bawat desisyon at labanan.",
      p3: "Ang Ragnamex Hardcore ay hindi lamang isang laro; ito ay isang matinding karanasan na sumusubok sa kasanayan, estratehiya, at tiyaga ng mga manlalaro. Sumali sa pakikipagsapalaran at tingnan kung mayroon kang kinakailangan upang mabuhay sa walang awang mundong ito!",
    },
    footer: "&copy; 2024 - GM Triedge",
    f_1: 'Modo Hardcore',
    f_1_description: 'Tanggapin ang hamon na maabot ang pinakamataas na antas sa Hardcore mode. Tanging ang mga pinaka-skillful at dedikadong manlalaro lamang ang maaaring makaabot sa tuktok, kung saan ang isang pagkakamali ay maaaring magdulot ng pagkawala ng lahat ng iyong progreso sa pakikipagsapalaran. Kung naghahanap ka ng tunay na hamon at nais patunayan ang iyong kakayahan, magugustuhan mo ang mode na ito. Sulitin ang adrenaline rush at subukan ang iyong mga hangganan!',
    f_2: 'Sistema ng mga Propesyon',
    f_2_description: 'Sa Ragnamex Hardcore, hindi ka lang dapat pumili ng klase para sa iyong pakikipagsapalaran, kundi pati na rin ng propesyon. Mayroon kaming iba\'t ibang propesyon na maaari mong pagpilian: mula sa pag-iipon, tulad ng pagmimina at herbolohiya, hanggang sa paggawa, tulad ng alkimya, pandayan, enchanting, pananahi, at marami pang iba. Palaging may mga manlalaro na pinahahalagahan ang bunga ng iyong trabaho. Pumili ka na at magpakatangi sa mundo ng Ragnamex Hardcore!',
    f_3: 'Sistema ng Auction',
    f_3_description: 'Ang Ragnamex Hardcore ay ang tanging server na may eksklusibong sistema ng auction. Bumili at magbenta ng mga item nang madali, habang ang aming sistema ang gagawa ng lahat ng trabaho para sa iyo, tinitiyak na ang iyong mga item ay mabibili sa mga kaakit-akit na presyo. Sumali at samantalahin ang makabagong pamilihang ito!',
    downloads: 'Pag-download',
    download: 'Mga Pag-download',
    register: 'Rehistro',
    buy_by: 'Bumili para sa',
    m_1: 'Mag-explore ng isang bago at kakaibang pakikipagsapalaran sa isang pamilyar na mundo, na may maraming bagong mga bagay at paraan ng paglalaro. Tangkilikin ang klasikong karanasan na may bagong kahulugan, at higit sa lahat... huwag kang mamatay! Kung mamatay ka man, tandaan na bawat kamatayan ay isang bagong simula lamang. Tanging ang pinaka matiyaga lamang ang nagtatagumpay sa mga malalaking tagumpay.',
    m_2: 'Maligayang pagdating sa mapanganib na mundo ng Ragnamex Hardcore!',
    page_vip1: 'Mga Bentahe ng VIP Mode',
    page_vip2: 'Tuklasin ang lahat ng benepisyo ng pagkakaroon ng aktibong VIP mode.',
    page_vip3: 'Pinahusay na Karanasan:',
    page_vip4: 'Ang karanasan mula sa mga halimaw ay tumataas ng +50%.',
    page_vip5: 'Battle Manual Experience +50%.',
    page_vip6: 'Eksklusibong Access:',
    page_vip7: 'Access sa isang Extra Storage.',
    page_vip8: 'Mga Utos ng VIP:',
    page_vip9: 'Pinapayagan ang paggamit ng utos na ito, na nagbibigay-daan sa pagbebenta kahit saan.',
    page_vip10: 'Ang oras ng cooldown ay ngayon 5 minuto, mula sa 30 minuto.',
    page_vip11: 'Mga Benepisyo sa Propesyon:',
    page_vip12: 'Pagbawas ng oras ng Crafting mula 50% hanggang 75%.',
    page_vip13: 'Pagkakataon na makakuha ng dagdag na mga mapagkukunan.',
    page_vip14: 'Pagkakataon na makakuha ng dagdag na produkto.',
    page_vip15: 'Pagkakataon na mabawasan ang mga gastos sa materyales.',
    ragnamex_token1: 'Ano ang Ragnamex Token?',
    ragnamex_token2: 'Alamin ang lahat tungkol sa Ragnamex Token at kung paano ka makikinabang dito.',
    ragnamex_token3: 'Pagkuha ng Ragnamex Token:',
    ragnamex_token4: 'Ang Ragnamex Token ay maaaring makuha sa halagang 15 Credits mula sa aming website.',
    ragnamex_token5: 'Mga opsyon kapag ginagamit ang Ragnamex Token:',
    ragnamex_token6: 'Kapag ginamit ang Ragnamex Token, bibigyan ang manlalaro ng dalawang opsyon:',
    ragnamex_token7: 'Makakuha ng 30 araw na VIP.',
    ragnamex_token8: 'Makakuha ng 700 Cashpoints.',
    ragnamex_token9: 'Palitan ng Ragnamex Token:',
    ragnamex_token10: 'Mahalagang tandaan na ang Ragnamex Token ay hindi nakatali sa manlalaro o sa account. Nangangahulugan ito na posible itong:',
    ragnamex_token11: 'Ipamalit sa ibang manlalaro.',
    ragnamex_token12: 'Ipagbili ito sa auction system at hintayin na may manlalaro na magbayad ng Zenys kapalit ng Ragnamex Token.',
    step_paypal1: 'Gabay sa Donasyon gamit ang PayPal',
    step_paypal2: 'Sundin ang mga hakbang sa ibaba upang gawin ang iyong donasyon.',
    step_paypal3: 'Hakbang 1',
    step_paypal4: 'Ang bawat USD na ipinadala sa pamamagitan ng PayPal ay katumbas ng 1 Credit.',
    step_paypal5: 'Hakbang 2',
    step_paypal6: 'Ipadala ang nais na halaga sa email: donate.imperialro@outlook.com',
    step_paypal7: 'Hakbang 3',
    step_paypal8: 'Kung maaari, ipadala ang pera bilang Donasyon.',
    step_paypal9: 'Hakbang 4',
    step_paypal10: 'Kapag nagawa na ang donasyon, makipag-ugnayan kay @gmtriedge sa Ragnamex Discord para sa beripikasyon.',
    step_paypal11: 'Hakbang 5',
    step_paypal12: 'Kapag nakumpleto na ang beripikasyon, ang mga Credits ay lilitaw sa iyong account.',
    service_p1: 'Mga Serbisyo ingame',
    service_p2: 'May mga ilang serbisyo ingame na magagamit sa loob ng laro.',
    service_p3: 'Pagpalit ng Kasarian ng Iyong Karakter',
    service_p4: 'Pinapayagan kang palitan ang kasarian ng iyong kasalukuyang karakter.',
    service_p5: 'Pagpalit ng Pangalan',
    service_p6: 'Pinapayagan kang palitan ang pangalan ng iyong karakter.',
    service_p7: 'Ilipat ang Iyong Karakter sa Ibang Account',
    service_p8: 'Pinapayagan kang ilipat ang iyong karakter sa ibang account.',
  }
};

const i18n = new VueI18n({
  locale: 'en', // idioma predeterminado
  fallbackLocale: 'es', // idioma de respaldo
  messages,
});

export default i18n;
