<template>
	<v-app id="inspire">
		<v-navigation-drawer
			v-model="drawer"
			app
		>
		<v-list>
			<v-list-item>
				<v-list-item-avatar
				color="grey darken-1"
				size="120"
				>
				<v-img src="/assets/imgs/facebook_profile_04.png" class="avatar-image"></v-img>
				</v-list-item-avatar>
			</v-list-item>

			<v-list-item link>
				<v-list-item-content>
				<v-list-item-title class="text-h6">
					{{ $t('welcome') }} {{user_name}}
				</v-list-item-title>
				<v-list-item-subtitle>{{email}}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			</v-list>
			<v-divider></v-divider>
		<span v-for="(item, i) in items"
		:key="i"
		>
		<router-link v-if="!item.has_subgroup" :to="item.route" tag="div">
			<v-list-item dense>
			<v-list-item-icon>
				<v-icon>{{ item.icon }}</v-icon>
			</v-list-item-icon>
			<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item>
		</router-link>
		<v-list-group dense  v-else>
			<template v-slot:activator>
			<v-list-item-content >
				<v-list-item-title v-text="item.title"></v-list-item-title>
			</v-list-item-content>
			</template>

			<v-list dense>
			<router-link
				v-for="(categoria, i) in item.categorias"
				:key="i"
				:to="categoria.route"
				tag="div"
			>
				<v-list-item dense>
				<v-list-item-icon>
					<v-icon>{{ categoria.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title v-text="categoria.name"></v-list-item-title>
				</v-list-item-content>
				</v-list-item>
			</router-link>
			</v-list>
		</v-list-group>

		</span>
		</v-navigation-drawer>
		
		<v-app-bar app>
			<v-app-bar-nav-icon @click="drawer = !drawer">
			</v-app-bar-nav-icon>
			<v-toolbar-title>Ragnamex Hardcore</v-toolbar-title>
			<v-divider
				vertical
				class="mx-4"
			></v-divider>
			<v-toolbar-title v-if="account_id>0" >Credits: {{ credit }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-btn icon color="black" href="https://discord.gg/ppMyV4e" target="_blak" ><Icon width="24" height="24" icon="ic:baseline-discord" /></v-btn>
				<span>Language:</span>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" icon>
						<span :class="`flag-icon flag-icon-${currentLanguage.flag}`"></span>
					</v-btn>
					</template>
					<v-list>
					<v-list-item
						v-for="lang in languages"
						:key="lang.code"
						@click="changeLanguage(lang.code)"
					>
						<v-list-item-avatar>
						<i :class="`flag-icon flag-icon-${lang.flag}`"></i>
						</v-list-item-avatar>
						<v-list-item-content>
						<v-list-item-title>{{ lang.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					</v-list>
				</v-menu>
				
			<v-divider
				vertical
				class="mx-4"
			></v-divider>
              <v-btn 
			  v-if="account_id>0"
			  small
			  color="primary" @click="Outlog">
                {{ $t('logout') }}
              </v-btn>
              <v-btn 
			  v-else
			  small
			  color="primary" @click="showLoginRegister=true">
                {{ $t('register') }} / {{ $t('login') }}
              </v-btn>
			</v-app-bar>
		<v-main dark>
			<router-view
			:account_id="account_id"
			:group_id="group_id"
			:user_name="user_name"
			:showlogin="showlogin"
			@login="Login"
			@updatecredits="getBalance"
			/>
		</v-main>
	<LoginRegister
		:show="showLoginRegister"
		@close="showLoginRegister=false"
		@registrar="Registro"
		@login="Login"
	/>
	</v-app>
	
</template>
<script>
import Vue from "vue";
import axios from 'axios';
import LoginRegister from "@/components/dialogRegisterLogin.vue";
import { Icon } from '@iconify/vue2';
//import DialogVentas from "@/components/ProductoVender.vue";
export default {
    components: {
    	Icon,
		LoginRegister

	},
  name: 'App',

  data: () => ({    
	account_id: 0,
    group_id: 0,
	credit: 0,
	cashpoints: 0,
	user_name: '',
	reference: '',
	token: '',
    name: 'Welcome',
	showlogin: true,
    email: '',
    status: false,
    drawer: true,
    mini: true,
    isLoading: false,
    model: null,
    search: '',
    showLoginRegister: false,
    showPublicarProducto: true,
    items_search: [],
    categorias:  [],
    items: [
      { title: 'Main', has_subgroup: false, icon: 'mdi-home', route: '/', },
      { title: 'Downloads', has_subgroup: false, icon: 'mdi-download', route: '/downloads', },
      { title: 'Guides', has_subgroup: false, icon: 'mdi-compass-rose', route: '/guides', },
      {
        title: 'Information', has_subgroup: true,
        categorias: [
			{ name: 'What is Ragnamex?', to: '/about', icon: '', route: '/about' },
			{ name: 'Features', to: '', icon: '', route: '/features' },
			{ name: 'Server Info', to: '', icon: '', route: '/serverinfo' },
			{ name: 'Ranking', to: '', icon: '', route: '/ranking' },
			{ name: 'Death Log', to: '', icon: '', route: '/deathlog' },
			{ name: 'Market', to: '', icon: '', route: '/market' }
		],
      },
      {
        title: 'Donations', has_subgroup: true,
        categorias: [
			{ name: 'How Donate', to: '', icon: '', route: '/donate' },
			{ name: 'VIP', to: '', icon: '', route: '/vip' },
			{ name: 'Cash Shop', to: '', icon: '', route: '/cashshop' },
			{ name: 'Services', to: '', icon: '', route: '/services' },
			{ name: 'Ragnamex Token', to: '', icon: '', route: '/tokenragnamex' }
		],
      }
    ],
	locales: ['en', 'es'],
	currentLocale: 'en',
	languages: [
		{ code: 'en', name: 'English', flag: 'us' },
        { code: 'es', name: 'Español', flag: 'es' },
        { code: 'pt', name: 'Português', flag: 'pt' },
        { code: 'fil', name: 'Filipino', flag: 'ph' },
      ],
  }),
  mounted() {	
	this.account_id = null;
	this.reference = null;
	this.user_name = null;
	this.token = null;
	if(localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")) !== null){
		this.userData = JSON.parse(localStorage.getItem("userData"));
		let timestamp = Date.now();
		if(this.userData.expire_time < timestamp){
			this.account_id = null;
			this.user_name = null;
		}else{
			this.account_id = this.userData.account_id;
			this.user_name = this.userData.user_name;
			this.token = this.userData.token;
		}
	}
},
  computed: {
    currentLanguage() {
      return this.languages.find(lang => lang.code === this.$i18n.locale);
    },
  },
  methods: {
    OpenProfile(){
      if(!this.account_id){
        this.showLoginRegister = true;
        return;
      }
    },
	changeLocale() {
      this.$i18n.locale = this.currentLocale;
    },
	Outlog(){
		this.account_id = null;
		this.user_name = null;
		this.token = null;
		this.my_refcode = null;
	},
	changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    Registro(value){
      let user = { ...value };
      let threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
      user.expire_time = Date.now() + threeDaysInMilliseconds;
      localStorage.setItem("userData", JSON.stringify(user));
      
      // Asignar propiedades a `this`
      this.account_id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.group_id = user.group_id;
      this.status = user.status;
      Vue.swal.fire({
        icon: "success",
        html: `<p>Registro completado con éxito</p><p>Gracias por registrarte ${user.name}</p>`
      });
      this.showLoginRegister = false;
      // Mostrar mensaje de bienvenida
    },
    Login(value){
		
		let timestamp = Date.now();
		let threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
		timestamp += threeDaysInMilliseconds;
		value.expire_time = timestamp;
		localStorage.setItem("userData", JSON.stringify(value));
		this.account_id = value.account_id;
		this.user_name = value.user_name;
		this.token = value.token;
		this.my_refcode = value.reference;
		this.showlogin = false;
		this.showLoginRegister = false;
		return;
		let newdata = value;
		console.log('Recibiendo informacion desde Main');
		console.log(newdata);
		return;
      let user = { ...value };
      user.expire_time = Date.now() + threeDaysInMilliseconds;
      localStorage.setItem("userData", JSON.stringify(user));
      // Asignar propiedades a `this`
      this.account_id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.group_id = user.group_id;
      this.status = user.status;
      Vue.swal.fire({
        icon: "success",
        html: `<p>Nos alegra verte de vuelta</p><p>${user.name}</p>`
      });
      this.showLoginRegister = false;
      // Mostrar mensaje de bienvenida

    },
    getBalance(value){
      axios
      .get(this.URL('balance/'+value))
      .then(response => {
        let t = response.data;
        this.credit = t.balance_credits;
        this.cashpoints = t.cashpoints;
      })
      .catch(this.MSGBOX);
    },
  },
  watch: {
	account_id : function (value){
		if(!value){
			this.credit = 0;
			this.cashpoints = 0;
			return;
		}
    	this.getBalance(value);
    }
  },
}
</script>
<style>
#app {
  background: url('@/assets/bg_01.jpg')
    no-repeat center center fixed !important;
  background-size: cover;
}
.clickable {
cursor: pointer;
}
.enchant-grade-0 {
color: #b01212;
}
.enchant-grade-1 {
color: #4038d6;
}
.enchant-grade-2 {
color: #b6b125;
}
.enchant-grade-3 {
color: #0b7812;
}
.enchant-grade-4 {
color: #2d54b6;
}
.enchant-grade-5 {
color: #c9afaf;
}
.enchant-grade-6 {
color: #552222;
}
.avatar-image {
  border: 2px solid rgb(79, 79, 79); /* Define el grosor y el color del borde según tus preferencias */
  border-radius: 50%; /* Opcional: para hacer que el borde sea redondeado en imágenes circulares */
}

</style>
