import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#191C24',
        secondary: '#191C24',
        accent: '#8F5FE8',
        error: '#FC424A',
        info: '#0090E7',
        success: '#00D25B',
        warning: '#FFAB00',
      },
      dark: {
        primary: '#191C24',
        secondary: '#191C24',
        accent: '#8F5FE8',
        error: '#FC424A',
        info: '#0090E7',
        success: '#00D25B',
        warning: '#FFAB00',
      },
    },
  },
});
