
export const URL = function (url, filtros = ''){
    const userData = JSON.parse(localStorage.getItem("userData")); // `userData` no cambia después de ser asignado
    const timestamp = Date.now(); // `timestamp` es constante

    if (userData && userData.expire_time >= timestamp) {
        const { token, account_id, group_id } = userData; // Destructuración de `userData`
        const params = new URLSearchParams(); // `params` es una instancia de `URLSearchParams` que no cambia

        if (token) params.append('token', token); // Añadimos parámetros si existen
        if (account_id) params.append('account_id', account_id);
        if (group_id != null && group_id >= 0) params.append('group_id', group_id);

        const queryString = params.toString(); // `queryString` es constante
        return `${url}?${queryString}${filtros}`; // Construimos y retornamos la URL completa
    }
    localStorage.removeItem("userData"); // Eliminamos `userData` si ha expirado
    return `${url}${filtros}`; // Retornamos la URL base con los filtros
}