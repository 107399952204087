<template>
    <div>
      <v-navigation-drawer
        v-model="drawer"
        left
        color="#111827"
        app
        :absolute="false"
        permanent
        >
        <v-list
          color="#111827"
          nav
          dense
        >
          <v-list-item-avatar size="200" elevation="2">
            <v-img src="assets/imgs/logo.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-group
            color="#111827"
            v-model="group"
            active-class="blue darken-3--text text--accent-4"
            >
            <v-list-item @click="showHome()" ><v-icon color="white" class="mr-2">mdi-home</v-icon><v-list-item-title class="text-caption white--text">Home</v-list-item-title></v-list-item>
            <v-list-item @click="showDialogDownload = true"><v-icon color="white" class="mr-2">mdi-download</v-icon><v-list-item-title class="text-caption white--text">Downloads</v-list-item-title></v-list-item>
            <v-list-item @click="showRules()"><v-icon color="white" class="mr-2">mdi-gavel</v-icon><v-list-item-title class="text-caption white--text">Rules</v-list-item-title></v-list-item>
            <v-divider class="my-4" color="gray"></v-divider>
            <v-list-item @click="showShop()"><v-icon color="white" class="mr-2">mdi-cart</v-icon><v-list-item-title class="text-caption white--text">Shop</v-list-item-title></v-list-item>
            <v-list-item @click="showAuctionHouse()"><v-icon color="white" class="mr-2">mdi-store-search</v-icon><v-list-item-title class="text-caption white--text">Auction House</v-list-item-title></v-list-item>
            <v-divider class="my-4" color="gray"></v-divider>
            <v-list-item @click="showServerInfo()"><v-icon color="white" class="mr-2">mdi-information</v-icon><v-list-item-title class="textcaption white--text">Server Info</v-list-item-title></v-list-item>
            <v-list-item @click="showServerFeature()"><v-icon color="white" class="mr-2">mdi-list-status</v-icon><v-list-item-title class="text-caption white--text">Server Features</v-list-item-title></v-list-item>
            <v-list-item @click="showWoE()"><v-icon color="white" class="mr-2">mdi-nuke</v-icon><v-list-item-title class="text-caption white--text">WoE Information</v-list-item-title></v-list-item>
            <v-list-item @click="showRanking()"><v-icon color="white" class="mr-2">mdi-view-list</v-icon><v-list-item-title class="text-caption white--text">Rankings</v-list-item-title></v-list-item>
            <v-divider class="my-4" color="gray"></v-divider>
            <v-list-item @click="showItemdb()"><v-icon color="white" class="mr-2">mdi-database-search</v-icon><v-list-item-title class="text-caption white--text">Item Database</v-list-item-title></v-list-item>
            <v-list-item @click="showMobdb()"><v-icon color="white" class="mr-2">mdi-database-search</v-icon><v-list-item-title class="text-caption white--text">Mob Database</v-list-item-title></v-list-item>
          </v-list-item-group>
      </v-list>
      </v-navigation-drawer>
      <v-dialog v-model="showDialogDownload" :width="700">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2" @click="showDialogDownload = false">
            mdi-close
          </v-icon>
          <v-spacer></v-spacer>
          <span class="font-weight-bold text-sm-body-1">Free Client Download</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class=" ">
          Thank you for downloading<br> Download Client Installation File.<br>Once saved to your hard disk, you can immediately begin to install game.
    </v-card-text>
       
        <v-card-title dense class="font-weight-bold text-sm-body-1 my-n4">Download from MediaFire<v-icon class="ml-2">mdi-download-circle</v-icon></v-card-title>
        <v-card-title dense class="font-weight-bold text-sm-body-1 my-n4">Download from Mega<v-icon class="ml-2">mdi-download-circle</v-icon></v-card-title>
        <v-card-title dense class="font-weight-bold text-sm-body-1 my-n4">Download from Google Drive<v-icon class="ml-2">mdi-download-circle</v-icon></v-card-title>
        <v-card-text>
         
        </v-card-text>
      </v-card>
      
    </v-dialog>
    </div>
  </template>
  
<script>
import axios from 'axios'; 
export default {
    props:[ 
        'account_id',
    ],
    data: () => ({
      drawer: true,
      group: null,
      hover: false,
      showDialogDownload: false,
    }),
    methods: {
      navigateToScreen(val) {
        if (this.$route.path !== val) {
          this.$router.push({ path: val });
        }
      },
      showHome(){ this.$emit('showhome'); },
      showRules(){ this.$emit('showrules'); },
      showShop(){ this.$emit('showshop'); },
      showServerFeature(){ this.$emit('showserverfeature'); },
      showServerInfo(){ this.$emit('showsserverinfo'); },
      showWoE(){ this.$emit('showwoe'); },
      showRanking(){ this.$emit('showranking'); },

      showItemdb(){ this.$emit('showitemdb'); },
      showMobdb(){ this.$emit('showmobdb'); },
      
      showAuctionHouse(){
        window.location = "/auction";
        return;
      },
    },
    watch: {
    },
};
</script>
<style>
  v-list-item:hover {
    background-color: #f0f0f0 !important;
  }
</style>