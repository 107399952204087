<template>
    <v-app class="blue-grey lighten-5">
    <ROToolbar></ROToolbar>
    <v-container fluid>
        <v-card dark style="height: 100vh" class="mx-12 px-3" 
            elevation="2"
        >
        <v-row dense class="pt-4">
            <v-col>{{itemid.name_english}}</v-col>
            <v-col>Item ID# {{ itemid.id }} ({{ itemid.name_aegis }}) </v-col>
        </v-row>
        <v-row dense>
            <v-col>Type </v-col>
            <v-col>Class </v-col>
            <v-col>Buy Price </v-col>
            <v-col>Sell Price </v-col>
            <v-col>Weight Price </v-col>
        </v-row>
        <v-row dense 
            class="pb-12">
            <v-col>Item Script</v-col>
            <v-col>{{ itemid.script }}</v-col>
        </v-row>
        <v-tabs
            v-model="tab"
            dark
            background-color="secondary"
        >
        <v-tabs-slider color="yellow"></v-tabs-slider>

        <v-tab key="1">Drop by</v-tab>
        <v-tab key="2">Recipe by</v-tab>
        <v-tab key="3">Component for</v-tab>
            <v-tabs-items dark v-model="tab">
                <v-tab-item dark key=1>
                    <v-data-table
                    dark
                    :headers="HeaderDropBy"
                    :items="dropby"
                    class="elevation-1"
                    >
                    <template v-slot:item.mvp_drop="{ item }">
                        <span>{{ item.mvp_drop ? 'MVP' : 'Normal' }}</span>
                    </template>
                    <template v-slot:item.rate="{ item }">
                        <span>{{ 100 * item.rate /10000  }}%</span>
                    </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item dark key=2>
                    <v-data-table
                    dark
                    :headers="HeaderRecipeBy"
                    :items="recipes"
                    class="elevation-1"
                    >
                        <template v-slot:item.name="{ item }">
                            <span>{{ item.name }}</span>
                        </template>
                        <template v-slot:item.Amount="{ item }">
                            <span>{{ item.Amount }}</span>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item dark key=3>
                    <v-data-table
                    dark
                    :headers="HeaderComponentBy"
                    :items="componentfor"
                    class="elevation-1"
                    >
                        <template v-slot:item.name="{ item }">
                            <span>{{ item.name }}</span>
                        </template>
                        <template v-slot:item.recipe="{ item }">
                            <span v-for="(ingredient, index) in item.recipe" :key="ingredient.material_id">
                                {{ ingredient.name }} x{{ ingredient.material_amount}}{{ index < item.recipe.length - 1 ? ',' : '' }}
                            </span>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        
        </v-card>
  </v-container>
    
    </v-app>
  </template>
  <style>
  .v-container {
    width: 55%;
    margin: 0 auto;
  }
</style>
  <script>
    import Vue from "vue";
    import axios from 'axios'; 
  
    export default {
      name: 'home',
  
      components: {
      },
      
      data: () => ({
          select: null,
          tab:0,
          itemid: [],
          dropby: [],
          componentfor: [],
          recipes: [],
          HeaderDropBy: [
            { text: 'Name', align: 'start', sortable: false, value: 'name' },
            { text: 'Level', value: 'level' },
            { text: 'MvP', value: 'mvp_drop' },
            { text: 'Rate', value: 'rate' },
          ],
          HeaderRecipeBy: [
            { text: 'Name', align: 'start', sortable: false, value: 'name' },
            { text: 'Amount', value: 'material_amount' },
          ],
          HeaderComponentBy: [
            { text: 'Name', align: 'start', sortable: false, value: 'name' },
            { text: 'Recipe', value: 'recipe' },
          ]
    }),
    mounted() {
      this.itemid = this.$route.params.id;
      this.getRegistro();
    },
    methods: {
        getRegistro(){
            axios
            .get('/item/'+this.itemid)
            .then(response => {
                // maneja la respuesta del servidor
                this.itemid = response.data;
                this.dropby = this.itemid.dropby;
                this.componentfor = this.itemid.componentfor;
                this.recipes = this.itemid.recipe;
            })
            .catch(error => {
                // maneja los errores
            });
        }
    }
  
  }
  </script>
  