<template>
  <v-toolbar
        dark
        class="pt-3"
        elevation="1"
      >
      <v-toolbar-title class="px-4 pb-6">RO DataBase</v-toolbar-title>
      <v-autocomplete
        v-model="model"
        :items="items"
        :search-input.sync="search"
        :loading="isLoading"
        clearable
        item-text="name"
        item-value="id"
        label="Search Items or Monsters"
        solo
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Search any...
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <roitemlist
            :itemdata="item"
          />
        </template>
      </v-autocomplete>
    </v-toolbar>
</template>
<style>
  .toolbarg {
    display: flex;
  }
</style>
<script>
import router from "@/router";
import roitemlist from '@/components/ro-itemlist'
import axios from 'axios'; 

export default {
	components: {
		roitemlist
	},
  props:[ 
    'itemdata'
  ],
	data: () => ({
		isLoading: false,
		items: [],
		model: null,
		search: null,
		select: null
	}),
  methods: {
    async getResult(val){
      let response = await axios.get(`/search/${val}`);
      //this.items = response.data;
      this.items = response.data;
      // .then(response => {
      //   this.items = response.data;
      // })
      //   .catch(err => {
      //   })
      //   .finally(() => (this.isLoading = false));
    }
  },
	watch: {
    model (val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
    search (val) {
      // Items have already been loaded
      this.isLoading = true;
      if(!val) return;
      this.getResult(val);

      // Lazily load input items
      // fetch('https://api.coingecko.com/api/v3/coins/list')
      //   .then(res => res.clone().json())
      //   .then(res => {
      //     this.items = res
      //   })
      //   .catch(err => {
      //   })
      //   .finally(() => (this.isLoading = false))
    },
  },
};
</script>
