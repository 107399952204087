import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import ROToolbar from "./components/ro-toolbar.vue";
import ROToolmain from "./components/ro-maintool.vue";
import RONavigator from "./components/ro-navegator.vue";
//import rologin from "./components/ro-login.vue";
import axios from "axios";
import { URL } from './prototypes.js';
import VueI18n from 'vue-i18n'


Vue.prototype.URL = URL;
Vue.config.productionTip = false;
Vue.component("ROToolbar", ROToolbar); 
Vue.component("ROToolmain", ROToolmain); 
Vue.component("ro-navigator", RONavigator); 
Vue.use(VueSweetalert2);
//Vue.component("ro-login", rologin); 
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.baseURL = "https://api.ragnamex.com/api";
//axios.defaults.params['token'] = '';
//axios.defaults.params['database_id'] = '';

const apiUrl = process.env.VUE_APP_API_BASE_URL || "http://127.0.0.1:8000/api";

axios.defaults.baseURL = apiUrl;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json'; // Establece Content-Type para todas las solicitudes
axios.defaults.params = {}
Vue.prototype.$axios = axios;

new Vue(
  {
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');

  Vue.prototype.SET_AXIOS_DEFAULT_PARAMS  = function () {
    axios.defaults.params['api_token'] = sessionStorage.getItem("token");
  }
  Vue.prototype.getRate = function (value) {
    // Asegúrate de que el valor esté entre 0 y 10,000
    if (value < 0) value = 0;
    if (value > 10000) value = 10000;

    // Convertir el valor a porcentaje (10000 corresponde a 100.00%)
    let percentage = (value / 100).toFixed(2); // División para obtener el porcentaje y limitar a 2 decimales

    // Retornar el valor como un string con el símbolo de porcentaje
    return percentage + '%';
}
  Vue.prototype.getRateMarket  = function (market_value,price) {
    let rate = '';
    rate = parseInt(price * 100 / market_value);
    if(rate > 999) rate = 999;
    rate = rate+'%';
    return rate;
  }
  Vue.prototype.getZenyValue  = function (v,c) {
    let value = parseInt(v);
    if(c)
      value = value.toLocaleString() + 'C';
    else
      value = value.toLocaleString() + 'z';
    return value;

  }
  Vue.prototype.getjobName  = function (class_id) {
    let class_name = '';
    switch(class_id){
      case 0: class_name = 'Novice'; break;
      case 1: class_name = 'Swordman'; break;
      case 2: class_name = 'Magician'; break;
      case 3: class_name = 'Archer'; break;
      case 4: class_name = 'Acolyte'; break;
      case 5: class_name = 'Merchant'; break;
      case 6: class_name = 'Thief'; break;
      case 7: class_name = 'Knight'; break;
      case 8: class_name = 'Priest'; break;
      case 9: class_name = 'Wizard'; break;
      case 10: class_name = 'Blacksmith'; break;
      case 11: class_name = 'Hunter'; break;
      case 12: class_name = 'Assassin'; break;
      case 13: class_name = 'Knight (Peco)'; break;
      case 14: class_name = 'Crusader'; break;
      case 15: class_name = 'Monk'; break;
      case 16: class_name = 'Sage'; break;
      case 17: class_name = 'Rogue'; break;
      case 18: class_name = 'Alchemist'; break;
      case 19: class_name = 'Bard'; break;
      case 20: class_name = 'Dancer'; break;
      case 21: class_name = 'Crusader (Peco)'; break;
      case 22: class_name = 'Wedding'; break;
      case 23: class_name = 'Super Novice'; break;
      case 24: class_name = 'Gunslinger'; break;
      case 25: class_name = 'Ninja'; break;
      case 4001: class_name = 'Novice High'; break;
      case 4002: class_name = 'Swordman High'; break;
      case 4003: class_name = 'Magician High'; break;
      case 4004: class_name = 'Archer High'; break;
      case 4005: class_name = 'Acolyte High'; break;
      case 4006: class_name = 'Merchant High'; break;
      case 4007: class_name = 'Thief High'; break;
      case 4008: class_name = 'Lord Knight'; break;
      case 4009: class_name = 'High Priest'; break;
      case 4010: class_name = 'High Wizard'; break;
      case 4011: class_name = 'Whitesmith'; break;
      case 4012: class_name = 'Sniper'; break;
      case 4013: class_name = 'Assassin Cross'; break;
      case 4014: class_name = 'Lord Knight (Peco)'; break;
      case 4015: class_name = 'Paladin'; break;
      case 4016: class_name = 'Champion'; break;
      case 4017: class_name = 'Professor'; break;
      case 4018: class_name = 'Stalker'; break;
      case 4019: class_name = 'Creator'; break;
      case 4020: class_name = 'Clown'; break;
      case 4021: class_name = 'Gypsy'; break;
      case 4022: class_name = 'Paladin (Peco)'; break;
      case 4023: class_name = 'Baby Novice'; break;
      case 4024: class_name = 'Baby Swordman'; break;
      case 4025: class_name = 'Baby Magician'; break;
      case 4026: class_name = 'Baby Archer'; break;
      case 4027: class_name = 'Baby Acolyte'; break;
      case 4028: class_name = 'Baby Merchant'; break;
      case 4029: class_name = 'Baby Thief'; break;
      case 4030: class_name = 'Baby Knight'; break;
      case 4031: class_name = 'Baby Priest'; break;
      case 4032: class_name = 'Baby Wizard'; break;
      case 4033: class_name = 'Baby Blacksmith'; break;
      case 4034: class_name = 'Baby Hunter'; break;
      case 4035: class_name = 'Baby Assassin'; break;
      case 4036: class_name = 'Baby Knight (Peco)'; break;
      case 4037: class_name = 'Baby Crusader'; break;
      case 4038: class_name = 'Baby Monk'; break;
      case 4039: class_name = 'Baby Sage'; break;
      case 4040: class_name = 'Baby Rogue'; break;
      case 4041: class_name = 'Baby Alchemist'; break;
      case 4042: class_name = 'Baby Bard'; break;
      case 4043: class_name = 'Baby Dancer'; break;
      case 4044: class_name = 'Baby Crusader (Peco)'; break;
      case 4045: class_name = 'Baby Super Novice'; break;
      case 4046: class_name = 'Taekwon'; break;
      case 4047: class_name = 'Star Gladiator'; break;
      case 4048: class_name = 'Star Gladiator (Union)'; break;
      case 4049: class_name = 'Soul Linker'; break;
      case 4050: class_name = 'Gangsi (Bongun/Munak)'; break;
      case 4051: class_name = 'Death Knight'; break;
      case 4052: class_name = 'Dark Collector'; break;
      case 4054: class_name = 'Rune Knight (Regular)'; break;
      case 4055: class_name = 'Warlock (Regular)'; break;
      case 4056: class_name = 'Ranger (Regular)'; break;
      case 4057: class_name = 'Arch Bishop (Regular)'; break;
      case 4058: class_name = 'Mechanic (Regular)'; break;
      case 4059: class_name = 'Guillotine Cross (Regular)'; break;
      case 4060: class_name = 'Rune Knight (Trans)'; break;
      case 4061: class_name = 'Warlock (Trans)'; break;
      case 4062: class_name = 'Ranger (Trans)'; break;
      case 4063: class_name = 'Arch Bishop (Trans)'; break;
      case 4064: class_name = 'Mechanic (Trans)'; break;
      case 4065: class_name = 'Guillotine Cross (Trans)'; break;
      case 4066: class_name = 'Royal Guard (Regular)'; break;
      case 4067: class_name = 'Sorcerer (Regular)'; break;
      case 4068: class_name = 'Minstrel (Regular)'; break;
      case 4069: class_name = 'Wanderer (Regular)'; break;
      case 4070: class_name = 'Sura (Regular)'; break;
      case 4071: class_name = 'Genetic (Regular)'; break;
      case 4072: class_name = 'Shadow Chaser (Regular)'; break;
      case 4073: class_name = 'Royal Guard (Trans)'; break;
      case 4074: class_name = 'Sorcerer (Trans)'; break;
      case 4075: class_name = 'Minstrel (Trans)'; break;
      case 4076: class_name = 'Wanderer (Trans)'; break;
      case 4077: class_name = 'Sura (Trans)'; break;
      case 4078: class_name = 'Genetic (Trans)'; break;
      case 4079: class_name = 'Shadow Chaser (Trans)'; break;
      case 4080: class_name = 'Rune Knight (Dragon) (Regular)'; break;
      case 4081: class_name = 'Rune Knight (Dragon) (Trans)'; break;
      case 4082: class_name = 'Royal Guard (Gryphon) (Regular)'; break;
      case 4083: class_name = 'Royal Guard (Gryphon) (Trans)'; break;
      case 4084: class_name = 'Ranger (Warg) (Regular)'; break;
      case 4085: class_name = 'Ranger (Warg) (Trans)'; break;
      case 4086: class_name = 'Mechanic (Mado) (Regular)'; break;
      case 4087: class_name = 'Mechanic (Mado) (Trans)'; break;
      case 4096: class_name = 'Baby Rune Knight'; break;
      case 4097: class_name = 'Baby Warlock'; break;
      case 4098: class_name = 'Baby Ranger'; break;
      case 4099: class_name = 'Baby Arch Bishop'; break;
      case 4100: class_name = 'Baby Mechanic'; break;
      case 4101: class_name = 'Baby Guillotine Cross'; break;
      case 4102: class_name = 'Baby Royal Guard'; break;
      case 4103: class_name = 'Baby Sorcerer'; break;
      case 4104: class_name = 'Baby Minstrel'; break;
      case 4105: class_name = 'Baby Wanderer'; break;
      case 4106: class_name = 'Baby Sura'; break;
      case 4107: class_name = 'Baby Genetic'; break;
      case 4108: class_name = 'Baby Shadow Chaser'; break;
      case 4109: class_name = 'Baby Rune Knight (Dragon)'; break;
      case 4110: class_name = 'Baby Royal Guard (Gryphon)'; break;
      case 4111: class_name = 'Baby Ranger (Warg)'; break;
      case 4112: class_name = 'Baby Mechanic (Mado)'; break;
      case 4190: class_name = 'Super Novice (Expanded)'; break;
      case 4191: class_name = 'Super Baby (Expanded)'; break;
      case 4211: class_name = 'Kagerou'; break;
      case 4212: class_name = 'Oboro'; break;
      case 4215: class_name = 'Rebellion'; break;
      case 4218: class_name = 'Summoner'; break;
      default: class_name = 'N/A'; break;
    }
    return class_name;
  }
  Vue.prototype.AddComas  = function (numero) {
    return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
Vue.prototype.MSGBOX = function (error) {

  if (error.message && !error.response){
    Vue.swal.fire({ icon: "error", title: "Error", text: error.message });
    return;
  }
   
  let response = error.response;
  let data = response.data;
  let status = response.status;

  switch(status){
    case 200: Vue.swal.fire({ icon: "success", title: "Information", text: data.message }); break;
    case 400: Vue.swal.fire({ icon: "warning", title: "AVISO", text: data.error }); break;
    case 401: Vue.swal.fire({ icon: "warning", title: "Acceso Denegado", text: "YOU ARE NOT AUTHORIZED TO ACCESS THIS ROUTE" }); localStorage.removeItem("userData"); break;
    case 403: Vue.swal.fire({ icon: "warning", title: "OPERATION REJECTED", text: "v" }); break;
    case 422: Vue.swal.fire({ icon: "warning", title: "AVISO", text: data.error }); break;
    default: Vue.swal.fire({ icon: "error", title: "Error", text: data.message }); break;
  }
  return status;
}